import React from "react"
import Layout from '../components/layout';
import Link from 'gatsby-link';
import {GatsbySeo} from 'gatsby-plugin-next-seo/src/meta/gatsby-seo';
import useSiteMetadata from '../hooks/use-site-metadata';

const NotFoundPage = ({location}) => {
  const { siteUrl, defaultImage } = useSiteMetadata();

  return (
      <Layout>
        <GatsbySeo
            title="Varlıktan Veriler"
            description="Varlıktan Veriler sitesinde aradığınız içeriğe ulaşamadık"
            openGraph={{
              url: `${siteUrl}${location.pathname}`,
              title: "Varlıktan Veriler",
              images: [
                {
                  url: `${siteUrl}${defaultImage}`,
                  width: 1200,
                  height: 630
                }
              ]
            }}
        />
        <section id="page-title">
          <div className="container">
            <h1>Sayfa Bulunamadı</h1>
            {/*<span>Kazım Yardımcı'nın eserleri</span>*/}
            {/*<ol className="breadcrumb">*/}
            {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
            {/*    <li className="breadcrumb-item active" aria-current="page">Blog</li>*/}
            {/*</ol>*/}
          </div>

        </section>
        <section id="content">
          <div className="content-wrap">
            <div className="container clearfix">
              <div className="row align-items-center col-mb-80">

                <div className="col-lg-6">
                  <div className="error404 center">404</div>
                </div>

                <div className="col-lg-6">

                  <div className="heading-block text-center text-lg-left border-bottom-0">
                    <h4>Ulaşmaya çalıştığınız sayfayı bulamadık.</h4>
                    <span>Diğer içeriklere göz atmak isterseniz aşağıdan:</span>
                  </div>

                  <div className="row col-mb-30">

                    <div className="col-6 col-sm-4 widget_links">
                      <ul>
                        <li><Link to="/">Anasayfa</Link></li>
                        <li><Link to={'/varliktan-veriler'}>Varlıktan Veriler</Link></li>
                        <li><Link to={'/kitaplar'}>Kitaplar</Link></li>
                      </ul>
                    </div>

                    <div className="col-6 col-sm-4 widget_links">
                      <ul>
                        <li><Link to="/tasavvuf-sohbetleri">Tasavvuf Sohbetleri</Link></li>
                        <li><Link to="/basindan-haberler">Basından Haberler</Link></li>
                        <li><Link to="/iletisim">İletişim</Link></li>
                      </ul>
                    </div>


                  </div>
                </div>

              </div>

            </div>
          </div>

        </section>
      </Layout>
  )
}

export default NotFoundPage
